import React from "react";
import { Link } from "react-router-dom";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);
/* React component where show/hide 
  functionality is implemented */
export default function Default() {
  const classes = useStyles();

  return (
    <div>
      <h4> 
        <Link to="/addProduct-page" className={classes.priceNew}>
        Ajouter un Produit
            </Link>
           {/*  <Link to="/catalogue-page" className={classes.priceNew}>
        Continuer vers le catalogue
            </Link> */}
         </h4>
    </div>
  );
}