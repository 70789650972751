import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
//import SnackbarContent from '@material-ui/core/SnackbarContent';

//import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import ArrowForwardIconUp from '@material-ui/icons/ArrowUpwardOutlined';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
//import ArrowDownwardIcon from '@material-ui/icons/ArrowDownwardOutlined';


// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
//import Checkbox from "@material-ui/core/Checkbox";
//import Check from "@material-ui/icons/Check";
//import FormControlLabel from "@material-ui/core/FormControlLabel";

import SectionPricing from "views/PricingPage/Sections/SectionAbonnement.js";

import NavPills from "components/NavPills/NavPills.js";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import Check from "@material-ui/icons/Check";
//import Warning from "@material-ui/icons/Warning";
//import ArrowForwardIcon from '@material-ui/icons/ArrowDownwardOutlined';

//import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
//import AddIcon from "@material-ui/icons/Add";
//import Gesture from "@material-ui/icons/Gesture";
//import AccountCircle from "@material-ui/icons/AccountCircle";// core components
//import Clearfix from "components/Clearfix/Clearfix.js";
//import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";


//import Button from "components/CustomButtons/Button.js";
import image from "assets/img/bg7.jpg";
//const ArrowForwardIconAnimated = styled(ArrowForwardIcon)``;
const useStyles = makeStyles(signupPageStyle);


export default function InteretsPro() {

  const classes = useStyles();
  const history = useHistory();

  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ behavior: 'smooth' });
  };

   

   const [numbers, setNumbers] = useState([
    { letter: 'R', value: sessionStorage.getItem("R"), color: "#ffa21a", qualite: "REALISTE" },
    { letter: 'I', value: sessionStorage.getItem("I"), color: "#00d3ee", qualite: "INVESTIGATEUR" },
    { letter: 'A', value: sessionStorage.getItem("A"), color: "#af2cc5", qualite: "ARTISTIQUE" },
    { letter: 'S', value: sessionStorage.getItem("S"), color: "#5cb860", qualite: "SOCIAL" },
    { letter: 'E', value: sessionStorage.getItem("E"), color: "#f55a4e", qualite: "ENTREPRENANT" },
    { letter: 'C', value: sessionStorage.getItem("C"), color: "#CCCD", qualite: "CONVENTIONNEL" },
  ]);

 // console.log(numbers)


  //const firstIndex = useMemo(() => numbers[0], [numbers]);

  const sortNumbers = () => {
    let sortedNumbers = [...numbers];
    sortedNumbers.sort((a, b) => b.value - a.value);
    setNumbers(sortedNumbers);
    sessionStorage.setItem("firstLetter", sortedNumbers[0].letter);
    sessionStorage.setItem("secondLetter", sortedNumbers[1].letter);
    resultatInteretPro(sortedNumbers[0].letter);
    resultatInteretPro2(sortedNumbers[1].letter);
  };

  const [isInteretProfessionnel, setInteretProfessionnel] = useState([]);
  const [isInteretProfessionnel2, setInteretProfessionnel2] = useState([]);

  const [isloadProfil, loadProfil] = useState(false);
  const [isEmail, setEmail] = useState('');
  const [isCreatedAbo, setCreatedAbo] = useState('');
  const [sLabelAbo, setLabelAbo] = useState('');
  const [isPriceAbo, setPriceAbo] = useState('');
  const [isEndAbo, setEndAbo] = useState('');
  const [isStopAbo, setStopAbo] = useState(3);
  const [isloadButtonRiasec, loadButtonRiasec] = useState(false);

  
  const resultatInteretPro = (idLetter) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetInteretProfessionnel.php`, {
        idLetter: idLetter,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          console.log(res.data)
          if (res.data.success === true) {
                  setInteretProfessionnel(res.data.interetProData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

 const showProfil = () => {
    history.push("/interetProfessionnel-page");
  }

  const creatProfil = () => {
    history.push("/");
  }
 const loadId = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectUser.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            setEmail(res.data.email);
            setCreatedAbo(res.data.createdAbo);
            setLabelAbo(res.data.labelAbo);
            setPriceAbo(res.data.priceAbo);
            setEndAbo(res.data.endAbo);
            setStopAbo(res.data.stopAbo);
            loadProfil(true);
            if(sessionStorage.getItem("abo12")===true)
              loadButtonRiasec(true);
          }
          else {
           // console.log('false')
            loadProfil(false);
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const resultatInteretPro2 = (idLetter) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetInteretProfessionnel.php`, {
        idLetter: idLetter,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            setInteretProfessionnel2(res.data.interetProData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const resultatRIASEC = () => {
    try {
      Axios.defaults.withCredentials = true;

      Axios.post(`${ApiConfig.baseUrl}/Controls/ResultatRIASEC.php`, {
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            sessionStorage.setItem("R", res.data.nombreR);
            sessionStorage.setItem("I", res.data.nombreI);
            sessionStorage.setItem("A", res.data.nombreA);
            sessionStorage.setItem("S", res.data.nombreS);
            sessionStorage.setItem("E", res.data.nombreE);
            sessionStorage.setItem("C", res.data.nombreC);
            sortNumbers();
            return;
          }
        })
    } catch (error) { throw error; }
  };

  useEffect(() => {
    resultatRIASEC();
    //sortNumbers();
    loadId();

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader_}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div id="topPage" className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <NavPills
                alignCenter
                color="rose"
                tabs={[
                  {
                    tabButton: "VOTRE PROFIL",
                  }
                ]}
              />
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                </GridItem>
              </GridContainer>

              <Card>
                <CardBody>
                  <div className={classes.spacePaddingS} />
                  <div className={classes.textCenter}>
                    <span className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Cliquer pour</span>
                    <div className={classes.spacePaddingS} />
                 
                      <Button size="sm" round color="primary" onClick={() => { creatProfil() }}
                    >
                      DEFINISSEZ VOTRE PROFIL
                    </Button>
                     {"\u00A0"} ou {"\u00A0"}
                  


                    <Button size="sm" round color="primary" onClick={() => { showProfil() }}
                    >
                      CHARGER VOTRE PROFIL
                    </Button>
                   


                    <div className={classes.spacePaddingS} />
                    <h6 className={classes.textWarning}>{ }</h6>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  {isloadProfil === false ?
                    <SectionPricing />
                    : <>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={classes.space30} />
                          <div className={classes.title}>

                            <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Votre e-mail : {isEmail}
                            </h5>
                            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Abonnement créé le : {isCreatedAbo}</p>
                            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Pour une durée de : {sLabelAbo}</p>

                            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Au prix de : {isPriceAbo} / mois</p>

                            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Fin d'abonnement le : {isEndAbo}</p>
                            <div className={classes.title}>
                            {isStopAbo===1 ? (<>
                              <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>En cliquant ci dessous votre abonnement sera résilié automatiquement à la fin de votre abonnement.</p>                                
                              <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Le prélèvement mensuel en sera annulé.</p>
                <Button  size="sm" round color="primary" href={`https://aimezvous.fr/stripeTransaction/lib/CancelAboBDC.php?&ID=${sessionStorage.getItem("id_ind")}`}>
                  Annuler mon Abonnement                 
                </Button>
                
                    <div className={classes.spacePaddingS} />                           
                           </>)
                            : isStopAbo===2 ? (
                              <>
                              <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Votre accès sera résilié automatiquement à la fin de la période en cours.</p>
                              <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Le prochain prélèvement mensuel est annulé.</p>

                              {/* <input type='button' class='btn  btn-raised btn-round disabled' value='Abonnement déjà annulé'/> */}

                              </>
                            )
                            : (
                              <>
                               <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Pour information, vous pourrez vous désabonner à la fin de votre période d'engagement si vous le souhaitez.</p>
                              <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Un bouton de désabonnement sera affiché dans votre interface 15 jours avant la fin de votre abonnement. </p>                             
                              </>
                            )
                          }
                            
                             
                            </div>
                          </div>
                        </GridItem>
                      </GridContainer>

                      <GridContainer className={classes.pullRightButton}>
                        <Button round className={classes.buttonPrimary} size="sm"
                          endIcon={<ArrowForwardIconUp />}
                          onClick={() => { smoothScroll('topPage') }}
                        >
                        </Button>
                      </GridContainer>

                      <br></br>

                    </>
                  }

                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};


