
import React, { useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
//import Remove from "@material-ui/icons/Remove";
//import Add from "@material-ui/icons/Add";
import SaveIcon from '@material-ui/icons/Save';

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
import { TextField } from '@material-ui/core/';

const useStyles = makeStyles(styles);

    export default function Compteur(props) {
        const classes = useStyles();
          const [messageEmptyLocalization, resultEmptyLocalization] = useState([]);
          const [messageConfirmAction, resultMessageConfirmAction] = useState([]);

      const { id_std } = props  
    
      const [nivfin1Result, setNivfin1Result] = useState([]);
      const [nivfin2Result, setNivfin2Result] = useState([]);
      const [nivfin3Result, setNivfin3Result] = useState([]);
      const [nivfinResult, setNivfinResult] = useState([]);
     
      const [nivfin1ToMove, setNivfin1ToMove] =  useState([]);
      const [nivfin2ToMove, setNivfin2ToMove] =  useState([]);
      const [nivfin3ToMove, setNivfin3ToMove] =  useState([]);
   

      const nivfinResultOnLoad = function (id_std) {
        try {
          Axios.defaults.withCredentials = true;
          Axios.post(`${ApiConfig.baseUrl}/Controls/GetQteStd.php`, {
            id_std: id_std,
            idLocalization: sessionStorage.getItem("idLocalization"), 
            idLevel: sessionStorage.getItem("idLevel"), 
            idZon: sessionStorage.getItem("idZon"),
            id_pro:sessionStorage.getItem("getIdOwnerSelect"),
          })
            .then(res => {
              setNivfin1Result(res.data.qteStdData.nivfin1);
              setNivfin2Result(res.data.qteStdData.nivfin2);
              setNivfin3Result(res.data.qteStdData.nivfin3);
              setNivfinResult(res.data.qteStdData.nivfinstock);
              return;           
            })
        } catch (error) { throw error; }
      }

      let nivfin1Res;//celle que l on affiche
      let nivfin2Res;//celle que l on affiche
      let nivfin3Res;//celle que l on affiche
      let nivfinRes;//celle que l on affiche
  
      if (nivfinResult.length === 0 ) {     
        nivfinResultOnLoad(id_std);
      }
      else {
        nivfinRes = nivfinResult;
        nivfin1Res = nivfin1Result;
        nivfin2Res = nivfin2Result;
        nivfin3Res = nivfin3Result;
      }

    /*   const handleClick = (event) => {
        let nbr1=null;
     
        setNivfin1ToMove(event.target.value);
        
         nbr1=event.target.value;
        setTimeout(() => {
          memQuantityItem(id_std, nbr1, 1);
        }, 2000);
        clearTimeout();
      };
      const handleClick2 = (event) => {
        let nbr2=null;
        setNivfin2ToMove(event.target.value);
        nbr2=event.target.value;
        setTimeout(() => {
          memQuantityItem(id_std, nbr2, 2);
        }, 2000);
        clearTimeout();
      };
      const handleClick3 = (event) => {
        let nbr3=null;
        setNivfin3ToMove(event.target.value);
        nbr3=event.target.value;
        setTimeout(() => {
          memQuantityItem(id_std, nbr3, 3);
        }, 2000);
        clearTimeout();
      };
    
      const memQuantityItem = async (id, qte, quality) => {
        sessionStorage.setItem("memQuantityItemID", id);
        sessionStorage.setItem("memQuantityItemQTE", qte);
        sessionStorage.setItem("memQuantityItemQUALITY", quality);
      } */

      const handleClick = (event) => {
        setNivfin1ToMove(event.target.value);
      };
      const handleClick2 = (event) => {
        setNivfin2ToMove(event.target.value);
      };
      const handleClick3 = (event) => {
        setNivfin3ToMove(event.target.value);
      };
      const setQuantityItem1 = () => {
        //console.log(id_std, nivfin1Temp, 1);
        setQuantityItem(id_std, nivfin1ToMove, 1);
    };
    const setQuantityItem2 = () => {
     // console.log(id_std, nivfin2Temp, 2);
        setQuantityItem(id_std, nivfin2ToMove, 2);
  };
  const setQuantityItem3 = () => {
    //console.log(id_std, nivfin3Temp, 3);
    setQuantityItem(id_std, nivfin3ToMove, 3);
};

      const setQuantityItem = async (id, qte, quality) => {
        if (sessionStorage.getItem("statut_clt") === 'modeAdmin'){

        if(sessionStorage.getItem("idLocalizationDest") && sessionStorage.getItem("idLevelDest") && sessionStorage.getItem("idZonDest") ){
        try {
          Axios.defaults.withCredentials = true;
          Axios.post(`${ApiConfig.baseUrl}/Controls/UpdateItemQte.php`, {
            id_std: id, 
            qte_std: qte, 
            qualityStd: quality, 
            idLocalization: sessionStorage.getItem("idLocalization"), 
            idLevel: sessionStorage.getItem("idLevel"), 
            idZon: sessionStorage.getItem("idZon"),
            id_pro: sessionStorage.getItem("getIdOwnerSelect"),   
            idLocalizationDest: sessionStorage.getItem("idLocalizationDest"),
            idLevelDest: sessionStorage.getItem("idLevelDest"),
            idZonDest: sessionStorage.getItem("idZonDest"),      
          })
            .then(res => {
              //console.log(res.data.query);

              if (res.data.success === true) {
              //console.log(res.data.success);
              setNivfin1Result(res.data.itemCountData.nivfin1);
              setNivfin2Result(res.data.itemCountData.nivfin2);
              setNivfin3Result(res.data.itemCountData.nivfin3);
              setNivfinResult(res.data.itemCountData.nivfinstock);
              setNivfin1ToMove('');
              setNivfin2ToMove('');
              setNivfin3ToMove('');
              resultMessageConfirmAction('Deplacement de '+res.data.itemCountData.qteStd+' element(s) '+res.data.itemCountData.qualityStd);// A voir si pour afficher un message apres validation
              resultEmptyLocalization('');
             /*  sessionStorage.removeItem("idLocalizationDest");
              sessionStorage.removeItem("idLevelDest");
              sessionStorage.removeItem("idZonDest");
 */
              }
              return;
            })
        } catch (error) { throw error; }
      }
      else{
        resultEmptyLocalization('Définissez la destination et la quantité');
       }
      }
      else{
        resultEmptyLocalization('Inventaire en consultation seulement');
       }
      };
  
      return <>
       <div className={classes.textCenter}>
              <br />
              <h6 className={classes.textWarning}>{messageEmptyLocalization}</h6>
              <h6 className={classes.textBlue}>{messageConfirmAction}</h6>
            </div>
       <div className={classes.mlAuto}>
                        <span className={classNames(classes.price, classes.priceNew)}>
                          Total: {nivfinRes}
                        </span>
                      </div>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <span key={1}>
                <div className={classes.buttonGroup}>
              Bon: {nivfin1Res}
                <br />
                  <TextField className={classes.input}
          type="text"
          onChange={handleClick}
          value={nivfin1ToMove}         
        />     <br />     
        <Button
                  color="info"
                  size="sm"
                  round
                  onClick={setQuantityItem1}
                  >
                  <SaveIcon />
                </Button>  
              </div>
            </span>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <span key={2}>
              <div className={classes.buttonGroup}>
              Fonctionnel: {nivfin2Res}
                <br />
                <TextField className={classes.input}
          type="text"
          onChange={handleClick2}
          value={nivfin2ToMove}         
        />          <br />     
        <Button
                  color="info"
                  size="sm"
                  round
                  onClick={setQuantityItem2}
                  >
                  <SaveIcon />
                </Button>
              </div>
            </span>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <span key={3}>
              <div className={classes.buttonGroup}>
              Hs: {nivfin3Res}
                <br />
                <TextField className={classes.input}
          type="text"
          onChange={handleClick3}
          value={nivfin3ToMove}         
        />    <br />     
       <Button
                  color="info"
                  size="sm"
                  round
                  onClick={setQuantityItem3}
                  >
                  <SaveIcon />
                </Button>
              </div>
            </span>
          </GridItem>
        </GridContainer>
      </>
    }