import React, { useState } from "react";
import Button from "components/CustomButtons/Button.js";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Remove from "@material-ui/icons/Remove";
export default function ToggleVisibility({ children }) {

  // React state to manage visibility
  const [show, setShow] = useState();

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);  
  }  
  if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
  return (
    <div className="component-container">
      {show && children}
      <Button
                color="warning"
                size="sm"
                round
                onClick={toggleShow}>
                Supprimer <Remove />
              </Button>
      {/* <Button
          color="tumblr"
           size="sm"
           round
           onClick={toggleShow}
         ><AddIcon /></Button> */}
    </div>
  );
}else return false;
}
/*
function Demo() {
  const [showAlert, setShowAlert] = useState(false);

  const handleDeleteAll = () => {
    // Perform your dangerous critical action here.
    // Remember to close your alert
    setShowAlert(false);
  };

  return (
    <Button onClick={() => setShowAlert(true)}>
      Delete all
    </Button>

    <Alert
      isOpen={showAlert}
      onClose={() => setShowAlert(false)} 
      title="Delete all"
      description="Are you sure you want to delete everything?"
      confirmBtnLabel="Yes"
      onConfirm={handleDeleteAll}
    />
  );
}
*/