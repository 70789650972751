import React, { useState } from "react";
import Button from "components/CustomButtons/Button.js";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Muted from "components/Typography/Muted.js";
export default function ToggleVisibility({ children }) {

  // React state to manage visibility
  const [show, setShow] = useState(false);

   function toggleShow() {
    setShow(!show);  
  }  
  if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
  return (
    <>
      {show && children} <br /> <br />
      <Muted>
           <h4><b>ou</b></h4>
          </Muted>
      <h4><b>Ajoutez une nouvelle information</b>
          </h4>
      <Button 
      justIcon
          color="tumblr"
           size="sm"
           round
           onClick={toggleShow}
         >  {show ? <RemoveIcon /> : <AddIcon />}</Button>
    </>
  );
}else return false;
}

