import React, { useState } from "react";
import Button from "components/CustomButtons/Button.js";

import AddIcon from "@material-ui/icons/Add";
export default function ToggleVisibility({ children }) {

  // React state to manage visibility
  const [show, setShow] = useState();

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);  
  }  
  if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
  return (
    <div className="component-container">
      {show && children}
      <Button
          color="tumblr"
           size="sm"
           round
           onClick={toggleShow}
         ><AddIcon /></Button>
    </div>
  );
}else return false;
}

